import React,{useState,useEffect} from 'react'
import Slider, { createSliderWithTooltip } from 'rc-slider';
// import 'rc-slider/assets/index.css';

function SliderSelector( props ) {
    const [value, setValue] = useState(1)
    const [min, setMin] = useState(props.min)
    const [max, setMax] = useState(props.max)
    const [step, setStep] = useState(1)
    const [valueCopy, setValueCopy] = useState(1)


    

    function log(value) {
        console.log('value',value); 
    }

    useEffect(() => {
        // log(value)
        props.setValue(value)


        if(value == 1 || value == 2){
            props.setValue(1);
            setValueCopy(1);
        }else if( value == 3 || value == 4){
            props.setValue(2);
            setValueCopy(2);
        }else if( value == 5 || value == 6){
            props.setValue(3);
            setValueCopy(3);
        }else{
            props.setValue(props.ranges[0].maxRange);
            setValueCopy(props.ranges[0].maxRange);
        }

    }, [value])



    return (
        <div className='option__slider--content'>
            <div className="slider__header--title">
                <h1>¿Cuántos servicios utilizas?</h1>
            </div>
            <div className='slider__container' >
                <div className="slider__selection">
                    {/* <span> 0{ value } servicios </span> */}
                    <span>{ value % 2 == 0 ? (valueCopy) + ' ½' : valueCopy } {value>1 ? "Servicios" : "Servicio"} </span>
                </div>
                <Slider value={value} min={min} max={max} step={step} onChange={setValue} />
            </div>
            <div className="slider__footer--options">
                <p>Regadera = <span>1 Servicio</span></p>
                <p>Lavabo = <span>1/2 Servicio</span></p>
            </div>
        </div>
    )
}

export default SliderSelector