import React, { useState, useEffect, useRef, Fragment } from 'react'
import { usePrismVersionResult } from '../../hooks/use_prism_versions-results'
import ResultHeader from './_result/result_header'
import { ButtonSquare } from '../buttons/_button_square'
import { useCurrenDevice, DEVICES } from '../../hooks/useCurrentDevice'
import { Picture } from 'react-responsive-picture';
import StarIcon from '../svg/commons/starr_icon'
import Slider from 'react-slick'
import { SliderSlickButton } from '../../components/buttons/_button_slider_slick';

const Result= (props) => {
    const resultQuery = usePrismVersionResult();
    // console.log("usePrismVersionResult", usePrismVersionResult)
    const  [versions,setVersions] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(1);

    const [device] = useCurrenDevice();
    const linkResult = useRef(null)
    // console.log('resultQuery',resultQuery);

    let resultMatrix = {
        'gas' : {
            'services': {
                '1': {
                    'persons': {
                        'a': ['c-101','cdp-06','cin-06b', 'cl-151', 'cdp-09', 'cin-11b'],
                        'b': ['cc-201','cdp-09','cin-13b', 'c-302', 'c-403']
                    }
                },
                '2': {
                    'persons': {
                        'a': ['c-101','cdp-06','cin-13b', 'cc-201', 'cdp-09'],
                        'b': ['c-302','cdp-09','cin-13b', 'c-403']
                    }
                },
                '3': {
                    'persons': {
                        'a': ['c-101','cdp-06','cin-06b','cc-201','cdp-09','cin-13b'],
                        'b': ['c-302','c-403'],
                    }
                },
                '4': {
                    'persons': {
                        'a': ['c-101','cdp-06','cin-06b','cc-201','cdp-09','cin-13b'],
                        'b': ['c-302','c-403'],
                    }
                }
            }
        },
        'electric' : {
            'services': {
                '1': {
                    'persons': {
                        'a': ['cie-10','cie-15'],
                        'b': ['cie-20','cie-30'],
                    }
                },
                '2': {
                    'persons': {
                        'a': ['cie-10','cie-20'],
                        'b': ['cie-30'],
                    }
                },
                '3': {
                    'persons': {
                        'a': ['cie-10','cie-20'],
                        'b': ['cie-30'],
                    }
                },
                '4': {
                    'persons': {
                        'a': ['cie-10','cie-20'],
                        'b': ['cie-30'],
                    }
                }
            }
        },
        'sun' : {
            'services': {
                '1': {
                    'persons': {
                        'a': ['vca-sl'],
                        'b': ['vca-sl'],
                    }
                },
                '2': {
                    'persons': {
                        'a': ['vca-sl'],
                        'b': ['vca-sl'],
                    }
                },
                '3': {
                    'persons': {
                        'a': ['vca-sl'],
                        'b': ['vca-sl'],
                    }
                },
                '4': {
                    'persons': {
                        'a': ['vca-sl'],
                        'b': ['vca-sl'],
                    }
                }
            }
        }
    };

    useEffect(() => {
        let versions_by_uid = [];
        //versions indexed by uid
        
        resultQuery.edges.map((_version)=>{
            if(_version!==undefined){              
                versions_by_uid[_version.node.uid] = _version;
            } 
        })

        let id_versions = resultMatrix[props.technologyType]['services'][props.services]['persons'][props.sequence[0]];
        
        let versions_array = [];

        id_versions.map((_version_id)=>{
            versions_array.push(versions_by_uid[_version_id]);
        })

        setVersions(versions_array);
    }, [])

    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        dots: true,
        dotsClass: 'pagination__dots',
        lazyLoad: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: ( index ) => { setCurrentSlide(index + 1) },
        nextArrow: <SliderSlickButton _className={`slick-button-next`}/>,
        prevArrow: <SliderSlickButton _className={`slick-button-prev` } />
    };

    function get_cover(_version,_key) {
        
        return (
            <div key={`result_body--cover${_key}`} className="result_body--cover">
                <Picture
                    sources={[
                        {
                            srcSet: _version.node.data.result_image.url,
                        }
                    ]}
                />
            </div>
        )
    }

    function get_uses(_version,_key) {
        
        return(
            <div key={`result_body--uses${_key}`} className="result_body--uses" >
                <div className="result__ideals">
                    <div className="check__icon">
                        <StarIcon fill='#febe23' />
                    </div>
                    <h2>Usos ideales:</h2>
                    <ul className='ideals__table'>
                        {
                            _version.node.data.model.document[0].data.body.map(element => {
                                if(element.__typename === "PrismicModelBodyUsosIdealesResultado"){
                                    return element.items.map((item,_key) => {
                                        return(
                                            <li key={`advantage-${_key}`} className='advantage__text' dangerouslySetInnerHTML={{__html: item.advantage.html}} />
                                        )
                                    })
                                }
                            })
                        }
                        {/* <li className='ideal__text'> {_version.node.data.model.document[0].data.ideal_list.text} </li> */}
                    </ul>
                </div>
            </div>
        )
    }

    function get_advantages(_version,_key) {
        
        return(
            <div key={`result_body--advantages${_key}`} className="result_body--advantages" >
                <div className="result__advantages">
                    <div className="check__icon">
                        <StarIcon fill='#febe23' />
                    </div>
                    <h2>Ventajas:</h2>
                    <ul className='advantages__table'>
                        {
                            _version.node.data.model.document[0].data.body.map(element => {
                                if(element.__typename === "PrismicModelBodyVentajasResultado"){
                                    return element.items.map((item,_key) => {
                                        return(
                                            <li key={`advantage-${_key}`} className='advantage__text' dangerouslySetInnerHTML={{__html: item.advantage.html}} />
                                        )
                                    })
                                }
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }

    function getMobileTemplate() {
        return (
            versions.map((_version,_key)=>{
                return (
                    <div key={`result_body_item${_key}`} className="result_body_item">
                        <ResultHeader
                            slug={_version.node.data.model.document[0].data.name.text}
                            name={_version.node.data.title.text}
                        />
                        <Slider key={`result_slider_${_key}`} {...settings}>
                            {
                                [
                                    get_cover(_version,_key),
                                    get_uses(_version,_key),
                                    get_advantages(_version,_key)
                                ]
                            }
                        </Slider>
                        <div className="result__links--container" ref={linkResult}>
                            <ButtonSquare
                                _bg_color="#2977BC"
                                _text='Conocer Modelo'
                                _to={`/calentadores/${_version.node.data.model.slug}`}
                                _className={`result__link model__link`}
                            />
                        </div>
                    </div>
                )
            })
        )
    }

    function getDesktopTemplate() {
        
        return (
            <Fragment>
                <Slider {...settings}>
                    {
                        versions.map((_version,_key)=>{
                            
                            return(
                                <div key={`result_body_item${_key}`} className="result_body_item">
                                    <ResultHeader
                                        slug={_version.node.data.model.document[0].data.name.text}
                                        name={_version.node.data.title.text}
                                    />
                                    <div className="result_content">
                                        <div className="content_left">
                                            {get_cover(_version,_key)}
                                        </div>
                                        <div className="content_right">
                                            {
                                                device == DEVICES.desktop &&
                                                <ResultHeader
                                                    slug={_version.node.data.model.document[0].data.name.text}
                                                    name={_version.node.data.title.text}
                                                />
                                            }
                                            {get_uses(_version,_key)}
                                            {get_advantages(_version,_key)}
                                            <div className="result__links--container" ref={linkResult}>
                                                <ButtonSquare
                                                    _bg_color="#2977BC"
                                                    _text='Conocer Modelo'
                                                    _to={`/calentadores/${_version.node.data.model.slug}`}
                                                    _className={`result__link model__link`}
                                                />
                                                <ButtonSquare
                                                    _bg_color="#febe23"
                                                    _text='Distribuidores'
                                                    _to={'/distribuidores/'}
                                                    _className={`result__link dealers__link`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                        })
                    }
                </Slider>
                <div className="result_count">
                    <span className="result_count_current">{ currentSlide > 9 ? `${currentSlide}` : `0${currentSlide}`}</span>
                    <span className="result_count_line"></span>
                    <span className="result_count_total">{ versions.length > 9 ? `${versions.length}` : `0${versions.length}`}</span>
                </div>
            </Fragment>
        )
    }

    if (resultQuery != null) {
        return (
            <div className='result__body'>
                {
                    window.innerWidth >= 768 ? getDesktopTemplate() : getMobileTemplate()
                }
            </div>
        )

    } else {
        return (
            <></>
        )
    }
}

export default Result
