import React,{useState} from 'react'
import {ButtonSemicircle, Button_Semicircle_types} from '../../components/buttons/_button_semicircle'

function TextOption( {question, items=[{}], handleClickSequence} ) {
    const [hover, setHover] = useState(false)

    function toggleHover() {
        setHover(!hover)
    }
    return (
        <div className="text__option--container">
            <div className="option__header">
                <h1> {question} </h1>
            </div>
            <div className="options__container">
                {
                    items.map((element,_key) => {
                        return(
                            <div className="text__option--click" key={`option-${_key}`} onClick={() => handleClickSequence(element.option)}>
                                <ButtonSemicircle 
                                    _slug={'option'} 
                                    _text={element.select}
                                    _className={`option__button rectangle`}
                                    _colorSpace='color__space'
                                    _button_type={hover ? Button_Semicircle_types.REGULAR : Button_Semicircle_types.LIGHT}
                                    onMouseEnter={()=> toggleHover()}
                                    onMouseLeave={()=> toggleHover()}
                                    >                
                                </ButtonSemicircle>
                            </div>
                        )
                    })
                }
                
            </div>

        </div>
    )
}

export default TextOption
