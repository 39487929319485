import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/help/help.scss'
import TextOption from '../components/help/text_option'
import CardOption from '../components/help/card_option'
import SliderSelector from '../components/help/slider_selector'
import LoadingScreen from '../components/help/loading'
import PaginatorNumber from '../components/help/paginator_number'

import Result from '../components/help/result'
import Head from '../components/head'
import CssClassManager from '../components/_helpers/_css_class_manager'
import ArrowHead from '../components/svg/commons/arrow_head'
import FooterWaveShape from '../components/svg/commons/footer_wave_shape'
import SemicircleShape from '../components/svg/commons/semicircle_shape'
import { ButtonSquare } from "../components/buttons/_button_square"

const Help= () => {
	// const [valor, setvalor] = useState([])
	const [option, setOption] = useState(1)
	const [loading, setLoading] = useState(false)
	const [result, setResult] = useState(false)
	const [total, setTotal] = useState(4)
	const [value, setValue] = useState(null)
	const [firstOption, setFirst] = useState('')
	const [search, setSearch] = useState('')
	const [sequence, setSequence] = useState([])
	const title = useRef(null)
	const instruccion = useRef(null)
	const paginator = useRef(null)
	let cssClassManager = new CssClassManager();

	const [hometype, setHomeType] = useState(1)
	const [technologyType, setTechnologyType] = useState(1)
	const [services, setServices] = useState(1)

	let logo;

	// const loadingButton = useRef(null)

	useEffect(() => {
		console.log('se cambio a ', option)
		if (option > 4) {
			console.log('termino ', option)
		}
	}, [option])


	useEffect(() => {
		document.getElementsByTagName('footer')[0].style.display = 'none'
		document.getElementById('header__container').classList.add('transparent')
		return () => {
			document.getElementsByTagName('footer')[0].style.display = 'block'
			document.getElementById('header__container').classList.remove('transparent')
		};
	})

	useEffect(() => {
		// console.log('first ', firstOption);
		if (firstOption === 'n') {
			// console.log('entro en la n');
			setTotal(5)
		} else {
			setTotal(4)
		}
	}, [firstOption])

	useEffect(() => {
		if (sequence[0] != null) {
			setFirst(sequence[0])
			// console.log('first ',firstOption);
		}

		// console.log('sequence ',sequence);

		if (sequence === []) return;
		sequence.map(element => {
			if (element === 'a' && element === 'b') {
				setOption(6)
			}
		})

	}, [sequence])

	/* useEffect(()=>{
		console.log('services',services)
	},[services]) */

	function handleLoadingScreen() {
		if (!loading) {
			title.current.style.display = 'none';
			paginator.current.style.display = 'none';
			setLoading(true)
			setTimeout(() => {
				setOption(6)
				setLoading(false)
				setResult(true)
				// console.log('se deactivo el loading', loading)
				// console.log('se encontro resultado ',result)
			}, 5000);
		}
	}

	const firstObject = [
		{ image: 'House', bodyTitle: 'Casa', bodyDesc: 'Necesito agua caliente para las manos, duchas o usos en cocina', selected: 'c' },
		{ image: 'Building', bodyTitle: 'Departamento', bodyDesc: 'Necesito agua caliente para las manos, duchas o usos en cocina', selected: 'd' }
	]

	const secondObject = [
		{ image: 'Flame', bodyTitle: 'Gas', bodyDesc: '', selected: 'gas' },
		{ image: 'Thunder', bodyTitle: 'Eléctrica', bodyDesc: '', selected: 'electric' },
		{ image: 'Sun', bodyTitle: 'Solar', bodyDesc: '', selected: 'sun' }
	]
	const secondObjectDepartment = [
		{ image: 'Flame', bodyTitle: 'Gas', bodyDesc: '', selected: 'g' },
		{ image: 'Thunder', bodyTitle: 'Eléctrica', bodyDesc: '', selected: 'e' }
	]

	/* const houseResult = [
		{ node: 'cg1a', result: 'insantaneo mudulante 1d' },
		{ node: 'cg1b', result: 'COXDP-11 de paso todo en uno' },
		{ node: 'cg2a', result: 'deposito g-10' },
		{ node: 'cg2b', result: 'deposito g-30' },
		{ node: 'ce1a', result: 'deposito e-10' },
		{ node: 'ce1b', result: 'deposito e-20' },
		{ node: 'ce2a', result: 'e30' },
		{ node: 'ce2b', result: 'e60' },
		{ node: 'cs1a', result: 'SL150' },
		{ node: 'cs1b', result: 'SL150' },
		{ node: 'cs2a', result: 'SL240' },
		{ node: 'cs2b', result: 'SL240' },
	] */
	/* const deptResult = [
		{ node: 'dg1a', result: 'COXDPI-07 B Instantáneo' },
		{ node: 'dg1b', result: 'COXDPI-13 B Instantáneo' },
		{ node: 'dg2a', result: 'COXDPE-06 Sin Piloto' },
		{ node: 'dg2b', result: 'COXDPE-11 Sin Piloto' },
		{ node: 'de1a', result: 'dep ele e-10' },
		{ node: 'de1b', result: 'dep ele e-20' },
		{ node: 'de2a', result: 'dep ele e-30' },
		{ node: 'de2b', result: 'dep ele e-40' },

	] */
	/* const bussResult = [
		{ node: 'ng1sa', result: 'insta cond 180s' },
		{ node: 'ng1sb', result: 'insta cond 180s *en cascada' },
		{ node: 'ng1ia', result: 'insta cokdpi 13' },
		{ node: 'ng1ib', result: 'insta cond 180s *en cascada' },
		{ node: 'ng2sa', result: 'insta cond 199s' },
		{ node: 'ng2sb', result: 'inst cond 199s *en cascada' },
		{ node: 'ng2ia', result: 'insta cond 199s coxdp 20' },
		{ node: 'ng2ib', result: 'insta cond 199 *en cascada' },
		{ node: 'nea', result: 'insta punto de uso' },
		{ node: 'neb', result: 'g252' },
		{ node: 'ne1sa', result: 'e40' },
		{ node: 'ne1sb', result: 'e60' },
		{ node: 'ne1ia', result: 'e20' },
		{ node: 'ne1ib', result: 'e30' },
		{ node: 'ns1a', result: 'SL150 multiconexion' },
		{ node: 'ns1b', result: 'SL150' },
		{ node: 'ns2a', result: 'SL240 multiconexion' },
		{ node: 'ns2b', result: 'SL240' },

	] */

	/* const resultInfo = [
		houseResult,
		deptResult,
		bussResult
	] */

	function handleClickSequence(selected) {
		// console.log('selected option ',selected)
		if (sequence[0] === '') {
			setSequence([selected])
		} else {
			setSequence([...sequence, selected])
		}
		handleNext()
		// console.log('handle click');
	}

	function changeHomeType(_type){
		setHomeType(_type);
		handleNext();
	}

	function changeTechnologyType(_type){
		setTechnologyType(_type);
		handleNext();
	}

	function getOptionSelector(option) {
		switch (option) {
			case 1:
				return <CardOption handleClickSequence={changeHomeType} handleNext={handleNext} numQuest={1} question={'¿Qué tipo de uso le darás a tu calentador?'} object={firstObject} />

			case 2:
				return <CardOption handleClickSequence={changeTechnologyType} handleNext={handleNext} numQuest={2} question={'¿Qué tipo de energía necesitas?'} object={secondObject} class_center='option__content--center' optionClass={true}/>

			case 3:
				cssClassManager.remove_class(document.getElementsByClassName('nav__next')[0], 'disable');
				return <SliderSelector setValue={setServices} min={1} max={7} ranges={[{maxRange: 4,option:'1'}]} />

			case 4:
				cssClassManager.add_class(document.getElementsByClassName('nav__next')[0], 'disable');
				return <TextOption handleClickSequence={handleClickSequence} question={'¿Cuántas personas van a disfrutar del agua caliente?'} items={[{ select: '1-2 personas', option: 'a' }, { select: '3+ personas', option: 'b' }]} />

			case 5:
				handleLoadingScreen()
				break;

			default:
				// console.log('se produjo un error')
				break;
		}
	}

	function handleNext() {
		// console.log('entra al handle Next');
		if (option <= total) {
			setOption(option + 1)
		}
		if (value != null) {
			setSequence([...sequence, value])
			setValue(null)
			// console.log('sequence next ',sequence);
		}
	}

	function handlePrev() {
		if (option > 1) {
			// instruccion.current.style.display = 'block';
			setOption(option - 1)
			// if(option != 3){
			setValue(null)
			let sequence2 = sequence;
			sequence2.pop()
			setSequence(sequence2)
			// }
		}
	}

	function resetForm() {
		setOption(1)
		setTotal(4)

		setSequence([])

		setServices(1)
		setTechnologyType(1)
		setValue(null)
		setResult(false)
	}

	function getHelp() {
		return <div className="help__container">
			{
				option < 5 ?
					<div className="back__container">
						{
							option === 1 ?
								<Link className='back__link' to='/'>
									<ArrowHead fill='#febe23'/>
									<p>Regresar</p>
								</Link>
								:
								<div className='back__link' onClick={() => handlePrev()}>
									<ArrowHead fill='#febe23'/>
									<p>Regresar</p>
								</div>
						}
					</div>
					:
					<Fragment />
			}
			<div className="header__title" ref={title}>
				<h1>Encuentra tu calentador ideal</h1>
			</div>
			{getOptionSelector(option)}
			{loading ? <LoadingScreen /> : null}
			<div className="help__navigation" ref={paginator} >
				<div className="nav__container">
					<ButtonSquare
						_bg_color="#2977BC"
						_text='Siguiente'
						_onClick={handleNext}
						_className={`nav__next ${option <= 2 || option > 4 ? 'disable' : ''}`}
						section={`help__pagination_next${option}`}
					/>
				</div>
				<div className="help__pagination">
					<PaginatorNumber active={option} total={total} />
				</div>
			</div>
		</div >
	}

	function getResult() {
		
		return <div className="result__container">
			<div className="back__container">
				<div className='back__link' onClick={() => resetForm()}>
					<ArrowHead fill='#febe23'/>
					<p>Iniciar</p>
				</div>
			</div>
			<Result hometype={hometype} technologyType={technologyType} services={services}  sequence={sequence} />
		</div>
	}

	return (
		<>
			<Layout chat={false}>
				<div className={result ? "result_footer__bg" : "help_footer__bg"}>
					<FooterWaveShape />
				</div>
				{
					result ?
						<>
							{getResult()}
						</>
						:
						<>
							{getHelp()}
						</>
				}
			</Layout>
		</>
	)
}

export default Help
