import React,{useState} from 'react'
import cardButtonStyles from '../../styles/_components/_buttons/_card_button.module.scss'
import House from '../svg/help/house'
import Building from '../svg/help/building'
import Bussiness from '../svg/help/bussiness'
import Flame from '../svg/help/flame'
import Thunder from '../svg/help/thunder'
import Sun from '../svg/help/sun'


function CardButton({image,bodyTitle,bodyDesc,_optionClass}) {
    const [icon, setIcon] = useState(image)

    function getIcon(icon) {
        if(icon === 'House'){
            return <img src="/assets/images/calentador_ideal/casa.png" alt=""/>
        }
        if(icon === 'Building'){
            return <img src="/assets/images/calentador_ideal/departamento.png" alt=""/>
        }
        if(icon === 'Bussiness'){
            return <img src="/assets/images/calentador_ideal/negocio.png" alt=""/>
        }
        if(icon === 'Flame'){
            return <img src="/assets/images/calentador_ideal/gas.png" alt=""/>
        }
        if(icon === 'Thunder'){
            return <img src="/assets/images/calentador_ideal/electrico.png" alt=""/>
        }
        if(icon === 'Sun'){
            return <img src="/assets/images/calentador_ideal/solar.png" alt=""/>
        }
    }

    return (
        <div className={cardButtonStyles.card__container}>
            <div className={cardButtonStyles.card__container_bgwhite}></div>
            
            <div className={cardButtonStyles.card__image}>
                {getIcon(icon)}
            </div>
            <div className={cardButtonStyles.card__body}>
                <div className={`${cardButtonStyles.card__title} ${_optionClass ? cardButtonStyles.energy : null}`}>
                    <h1>{bodyTitle}</h1>
                </div>
                <div className={cardButtonStyles.card__description}>
                    <p>{bodyDesc}</p>
                </div>
            </div>
        </div>
    )
}

export default CardButton
