import { useStaticQuery, graphql } from "gatsby"
export const usePrismVersionResult = () => {
const prismic = useStaticQuery(
    graphql`
        query {
            allPrismicVersion {
                edges {
                    node {
                        uid
                        data {
                            title{
                                text
                            }
                            model {
                                slug
                                document {
                                    uid
                                    data {
                                        claim {
                                            text
                                        }
                                        name {
                                            text
                                        }
                                        ideal_copy{
                                            text
                                        }
                                        ideal_list{
                                            text
                                        }
                                        body {
                                            __typename
                                            ... on PrismicModelBodyVentajas{
                                                items{
                                                    advantage_title_stroke{
                                                        text
                                                    }
                                                    advantage_title{
                                                        text
                                                    }
                                                }
                                            }
                                            __typename
                                            ... on PrismicModelBodyVentajasResultado {
                                                items {
                                                advantage {
                                                    text,
                                                    html
                                                }
                                                }
                                            }
                                            __typename
                                            ... on PrismicModelBodyUsosIdealesResultado {
                                                items {
                                                advantage {
                                                    text
                                                    html
                                                }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            result_image {
                                url
                                mb {
                                    url
                                }
                                tb {
                                    url
                                }
                                lg {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  );

return prismic.allPrismicVersion

}