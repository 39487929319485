import React,{useState} from 'react'
import CardButton from '../buttons/_card_button'

function CardOption( {numQuest, question, object=[{}], handleClickSequence, optionClass} ) {

    return (
        <div className="card__option--container">
            <div className="option__header">
                <h1> {question} </h1>
            </div>
            <div className="option__content">
                {
                    object.map((element,_key) => {
                        return(
                            <div className="card__click"  key={`${numQuest}-${_key}`} onClick={() => handleClickSequence(element.selected)}>
                                <CardButton _optionClass={optionClass} image={element.image} bodyTitle={ element.bodyTitle} bodyDesc={element.bodyDesc} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default CardOption