import React from 'react'
import { TitleStroke } from '../../_titles/_header_title_stroke'

function ResultHeader({ slug, title, name, colorStart, colorEnd }) {
    return (
        <div className="result__header">
            <div className="result__title">Te Presentamos</div>
            <TitleStroke
                    useH1={true}
                    textStroke={name}
                    text={slug}
                    colorStroke="#001f5b"
                    colorText="#001f5b"
            />
        </div>
    )
}

export default ResultHeader
