import React from 'react'
import Bubbles from '../_animations/bubbles'
import { TitleStroke } from '../_titles/_header_title_stroke'
import CinsaLogo from '../svg/commons/cinsa_logo'

function Loading() {
    const titleStroke = 'Estamos buscando'
    const titleFilled = 'el calentador ideal para ti'
    return (
        <div className="loading__page">
            <div className="loading__animation">
                <Bubbles />
            </div>
            <div className="header__loading">
                <CinsaLogo
                    text_visible="false"
                    className="loading__logo"
                />
                <TitleStroke
                    useH1={false}
                    textStroke={titleStroke}
                    text={titleFilled}
                    colorStroke="#001f5b"
                    colorText="#001f5b"
                    _className="loading__screen--title"
                />
            </div>
        </div>
    )
}

export default Loading
