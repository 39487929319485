import React from 'react';
import { Link } from 'gatsby';
import styles from '../../styles/_components/_buttons/_button_semicircle.module.scss';

export const Button_Semicircle_types = {
    LIGHT: 'oButtonSemicircleLight',
    REGULAR: ''    
  }

export const ButtonSemicircle= ({_text,_slug,_to='',_colorSpace='',_textColor='',_className,_button_type,_activeClassName,_onClick,_is_selected=false})=>{
    
    let type= styles[ _button_type || Button_Semicircle_types.REGULAR];    
    // console.log('_is_selected',_is_selected);
    let class_light= _button_type===Button_Semicircle_types.LIGHT ? 'o-button-semicircle--light': ' '; 
    let class_selected = _is_selected ? 'o-button-semicircle--selected' : '';
    // console.log('class_selected',class_selected);
    return (
        _to ==='' ?
            _onClick ? 
                <div onClick={_onClick}               
                    className={`${_className} ${styles.oButtonSemicircle} ${type} ${class_light} ${class_selected}` }>
                    <div className={`${styles.oButtonSemicircleWhite} ${_colorSpace}`}></div>
                    <p className={styles.oButtonSemicircle_type_item}>{_text}</p>
                </div>
            :
            <div  href="#"                
                className={`${_className} ${styles.oButtonSemicircle} ${type} ${class_light}` } >
                <div className={`${styles.oButtonSemicircleWhite} ${_colorSpace}`}></div>
                <p className={styles.oButtonSemicircle_type_item}>{_text}</p>
            </div>
        :   <Link  to={_to}                
                className={`${_className} ${styles.oButtonSemicircle} ${type} ${class_light} ${class_selected} ${_textColor} `} activeClassName={_activeClassName}>
                <div className={`${styles.oButtonSemicircleWhite} ${_colorSpace}`}></div>
                <p className={`${styles.oButtonSemicircle_type_item} ${_textColor}`}>{_text}</p>
            </Link>

    );
}