import * as React from "react"

const FooterWaveShape = (props) => (
    <div className="footer__bg">
        <svg viewBox="0 0 1920 350" {...props}>
            <linearGradient
                id="prefix__a54321"
                gradientUnits="userSpaceOnUse"
                x1={961.819}
                y1={348.532}
                x2={958.327}
                y2={21.814}
            >
                <stop offset={0} stopColor="#2746c6" />
                <stop offset={0.409} stopColor="#2746c6" />
                <stop offset={0.58} stopColor="#2b4ccc" />
                <stop offset={0.817} stopColor="#355dde" />
                <stop offset={1} stopColor="#406ef1" />
            </linearGradient>
            <path
                d="M0 350V56.01c5.51 1.22 13.45 2.97 23 5.03 53.44 11.57 94.18 19.49 108 22.15 36.2 6.98 54.3 10.46 77 14.1 38.08 6.09 67 9.38 91 12.08 33.76 3.8 61.92 6.96 100 9.06 35.74 1.97 61.92 1.99 96 2.01 49.33.04 88-1.3 106-2.01 71.54-2.83 125.27-8.03 187-14.1 85.73-8.43 150.02-16.82 214-25.17 36.55-4.77 86.28-11.29 152-21.14 67.85-10.17 76.91-12.53 138-21.14 67.76-9.55 115.85-15 135-17.12 35.3-3.9 60.63-6.25 91-9.06 65.23-6.04 98.59-9.09 135-10.07 18.53-.5 22.54-.2 79 0 61.36.22 80.14-1.52 115 0 31.54 1.38 57.01 4.08 73 6.04V350H0z"
                fill="url(#prefix__a54321)"
            />
        </svg>
    </div>
)

export default FooterWaveShape